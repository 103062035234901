import { FC, useEffect, useMemo, useRef } from 'react'
import { motion, LayoutGroup, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Popover,
  Transition,
  Portal,
  Text,
  CloseIconButton,
  Divider,
  useMediaQuery,
  PopoverBag,
  Icon,
} from '@lounge-fe/ui-kit'

import { BasketProps } from './types'
import { GiftWrap } from './GiftWrap'
import { ItemAddedAlert } from './ItemAddedAlert'
import { GiftWrapPlugin, UnderwearCareBagPlugin } from '../../../cart/plugins'
import { useShopify } from '../../../../hooks/useShopify'
import { BasketLines } from './BasketLines'
import classNames from 'classnames'
import { useCart } from '@/features/cart/hooks/useCart'
import { useCartPlugin } from '@/features/cart/hooks/useCartPlugin'
import { useRecentlyAddedProducts } from '@/features/cart/hooks/useRecentlyAddedProducts'
import { useCartContext } from '@/features/cart'
import { ChoiceChipQuickAdd } from './ChoiceChipQuickAdd'
import { DiscountProgress } from './DiscountProgress'
import { UnderwearCareBag } from './UnderwearCareBag'
import { BasketSummary } from './BasketSummary'
import { Money } from '@shopify/hydrogen-react'

const BasketInner: FC<BasketProps & PopoverBag> = ({
  onClose,
  onOpen,
  emptyBagAction,
  isOpen,
  choiceChipQuickAdd,
  cartDiscountProgress,
}) => {
  const { t } = useTranslation()
  const cart = useCart()
  const plugin = useCartPlugin()

  const { recentlyAddedTimer } = useCartContext()

  const { recentlyAddedProductIds, clearRecentlyAddedProductIds } =
    useRecentlyAddedProducts()

  const hasRecentlyAdded = recentlyAddedProductIds.length > 0

  const { routes, currency } = useShopify()

  const {
    itemCount = 0,
    itemsSubtotalPrice = 0,
    totalPrice = 0,
    totalDiscount = 0,
  } = cart?.data || {}

  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.sm})`)

  const isXLDesktop = useMediaQuery(
    ({ screens }) => `(min-width: ${screens['2xl']})`
  )

  useEffect(() => {
    if (isXLDesktop) {
      hasRecentlyAdded ? onOpen() : onClose()
    }
  }, [hasRecentlyAdded, isXLDesktop])

  const isGiftWrapEnabled = useMemo(
    () => plugin(GiftWrapPlugin)?.enabled || false,
    []
  )

  const isUnderwearCareBagPluginEnabled = useMemo(
    () => plugin(UnderwearCareBagPlugin)?.enabled || false,
    []
  )

  useEffect(() => {
    if (!isOpen) {
      recentlyAddedTimer.resume()
    }
  }, [isOpen])

  return (
    <>
      <Popover.Trigger>
        <button
          aria-label="Basket"
          className="cart-drawer__button flex gap-1 items-end cursor-pointer"
          onClickCapture={() => {
            clearRecentlyAddedProductIds()
          }}
        >
          <Icon.Bag width={20} height={20} />
          <span className="typography typography--body-sm">
            {itemCount >= 99 ? '99+' : itemCount}
          </span>
        </button>
      </Popover.Trigger>
      <Portal>
        <Popover.Backdrop className="z-40" />
        <Transition.Slide
          direction={isDesktop ? 'right' : 'bottom'}
          className={classNames(
            'basket-drawer fixed top-0 right-0 bottom-0 z-50 w-full max-w-full sm:max-w-[498px] overflow-hidden',
            isDesktop ? 'top-0' : 'top-[10%] rounded-t-md'
          )}
          data-testid="basket-desktop-transition"
        >
          <Popover.Content
            className="flex flex-col w-full h-full bg-white"
            onMouseEnter={recentlyAddedTimer.pause}
            onMouseLeave={recentlyAddedTimer.start}
          >
            <div className="flex justify-between py-4 mx-4 2xl:mx-10 2xl:pt-8 2xl:pb-7 border-b border-[#E5E5E5]">
              <Text variant="body-lg" className="capitalize font-medium">
                {t('cart.my_bag')}
              </Text>
              <CloseIconButton
                className="!bg-[#F5F5F5]"
                aria-label="Close bag"
                onClick={onClose}
              />
            </div>
            <AnimatePresence>
              {hasRecentlyAdded && <ItemAddedAlert />}
            </AnimatePresence>
            {cartDiscountProgress && (
              <DiscountProgress
                className="my-4 mx-4 2xl:mx-10"
                minimumSpend={cartDiscountProgress.minimumSpend}
                eligibleText={cartDiscountProgress.eligibleText}
                eligibleUrl={cartDiscountProgress.eligibleUrl}
                eligibleUrlText={cartDiscountProgress.eligibleUrlText}
                eligibleCollections={cartDiscountProgress.eligibleCollections}
                ineligibleText={cartDiscountProgress.ineligibleText}
              />
            )}
            <LayoutGroup>
              <motion.div className="relative flex flex-col flex-1 overflow-y-scroll">
                <BasketLines className="mx-4 my-3 2xl:my-6 2xl:mx-10 2xl:mb-0" />
                {choiceChipQuickAdd && (
                  <motion.div
                    layout="position"
                    className="flex-none mx-4 mb-6 2xl:mx-10"
                  >
                    <Divider className="mb-6" />
                    <ChoiceChipQuickAdd {...choiceChipQuickAdd} />
                  </motion.div>
                )}
                {Boolean(itemCount) && (
                  <>
                    <div className="grid gap-4 mx-4 2xl:gap-6 2xl:mx-10">
                      <Divider />
                      {isGiftWrapEnabled && <GiftWrap />}
                      {isUnderwearCareBagPluginEnabled && <UnderwearCareBag />}
                    </div>
                    <BasketSummary>
                      {itemsSubtotalPrice != totalPrice && (
                        <BasketSummary.Row>
                          <Text variant="body-md" className="font-regular">
                            {t('cart.subtotal')}:
                          </Text>
                          <Money
                            data={{
                              currencyCode: currency.active,
                              amount: (itemsSubtotalPrice / 100).toString(),
                            }}
                          />
                        </BasketSummary.Row>
                      )}
                      {totalDiscount > 0 && (
                        <BasketSummary.Row>
                          <Text variant="body-md" className="font-regular">
                            {t('cart.discount')}:
                          </Text>
                          <Money
                            data={{
                              currencyCode: currency.active,
                              amount: ((totalDiscount / 100) * -1).toString(),
                            }}
                          />
                        </BasketSummary.Row>
                      )}
                      <BasketSummary.Row className="items-center">
                        <Text variant="body-lg" className="font-medium">
                          {t('cart.total')}:
                        </Text>
                        <Text
                          as={Money}
                          variant="title-five"
                          data={{
                            currencyCode: currency.active,
                            amount: (totalPrice / 100).toString(),
                          }}
                        />
                      </BasketSummary.Row>
                    </BasketSummary>
                  </>
                )}
              </motion.div>
            </LayoutGroup>

            <div className="flex flex-col">
              <div className="flex flex-row flex-wrap gap-4 md:gap-y-4 md:gap-x-6 border-t py-4 px-4 2xl:px-10 2xl:pt-6 2xl:pb-8 border-[#E5E5E5] sm:[&_>_*]:flex-1">
                {itemCount > 0 || !emptyBagAction ? (
                  <>
                    <Button
                      as="a"
                      href={routes.cart_url}
                      key="view-bag"
                      className="whitespace-nowrap grow"
                      variant="outline"
                    >
                      {t('cart.view_bag')} ({itemCount})
                    </Button>
                    <Button
                      as="a"
                      href="/checkout"
                      key="checkout"
                      variant="solid"
                      className="whitespace-nowrap grow"
                    >
                      {t('cart.checkout')}
                    </Button>
                  </>
                ) : (
                  <Button
                    as="a"
                    href={emptyBagAction.url}
                    key="empty-bag"
                    variant="solid"
                  >
                    {emptyBagAction.text}
                  </Button>
                )}
              </div>
            </div>
          </Popover.Content>
        </Transition.Slide>
      </Portal>
      {!isXLDesktop && (
        <Portal>
          <Transition.Slide
            className="fixed bottom-0 left-0 right-0 z-30"
            show={Boolean(hasRecentlyAdded)}
            direction="bottom"
            data-testid="basket-mobile-transition"
          >
            <ItemAddedAlert
              id="item-added-alert"
              aria-labelledby="item-added-alert"
              role="status"
            />
          </Transition.Slide>
        </Portal>
      )}
    </>
  )
}

export const Basket = ({ ...props }: BasketProps) => (
  <Popover>
    {(popoverBag) => <BasketInner {...props} {...popoverBag} />}
  </Popover>
)
